import { Component } from '@angular/core';

@Component({
  selector: 'ngx-footer',
  styleUrls: ['./footer.component.scss'],
  template: `
    <span class="created-by">Powered by <img src="../../../assets/images/dotsoftLogo.png" width="150px"></span>
   
  `,
})
export class FooterComponent {
}
