import { NgModule } from '@angular/core';
import { ThemeModule } from '@app/@theme/theme.module';
import { MobileAppComponent } from './mobileAppDialog.component';
import { NgChartjsModule } from 'ng-chartjs';
import {
  NbCardModule
} from '@nebular/theme';
@NgModule({
  imports: [
    NbCardModule
  ],
  exports: [MobileAppComponent],
  declarations: [
    MobileAppComponent
  ],
})
export class MobileAppModule { }
