import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable } from 'rxjs/Observable';
import { of } from 'rxjs/observable/of';
import { catchError } from 'rxjs/operators';

import { environment } from '@env/environment';

const httpOptions = {
  headers: new HttpHeaders({ 'Content-Type': 'application/json' })
};

@Injectable()
export class UserService {

  private apiUrl = environment.api.endpoints.base + 'user';  // URL to web api

  constructor(private http: HttpClient) { }

  /** PUT: update user */
  public update(data: any): Observable<any> {
    var url=this.apiUrl+"/admin";
    return this.http.put(url, data, httpOptions)
      .pipe(
        catchError(this.handleError('updateUser'))
      );
  }

  public updatePass(data: any): Observable<any> {
    var url=this.apiUrl;
    return this.http.put(url, data, httpOptions)
      .pipe(
        catchError(this.handleError('updateUser'))
      );
  }

  /** PUT: update user */
  public create(data: any): Observable<any> {
    return this.http.post(this.apiUrl, data, httpOptions)
      .pipe(
        catchError(this.handleError('updateUser'))
      );
  }

  public createAdmin(data: any): Observable<any> {
    var url=this.apiUrl+'/admin'
    return this.http.post(url, data, httpOptions)
      .pipe(
        catchError(this.handleError('updateUser'))
      );
  }

  public GetUserDetails(): Observable<any>{
    return this.http.get<any>(this.apiUrl,httpOptions).pipe(
      catchError(this.handleError('getUserDetails'))
    );
  }

  public GetUserList(): Observable<any>{
    var url=this.apiUrl+'/list'
    return this.http.get<any>(url,httpOptions).pipe(
      catchError(this.handleError('getUseList'))
    );
  }

  public DeleteUser(id:number): Observable<any>{
    var url=this.apiUrl+'?id='+id
    return this.http.delete<any>(url,httpOptions).pipe(
      catchError(this.handleError('getUseList'))
    );
  }
  /**
   * Handle Http operation that failed.
   * Let the app continue.
   * @param operation - name of the operation that failed
   * @param result - optional value to return as the observable result
   */
  private handleError<T>(operation = 'operation', result?: T) {
    return (error: any): Observable<T> => {

      // TODO: send the error to remote logging infrastructure
      console.error(error); // log to console instead

      // Let the app keep running by returning an empty result.
      return of(result as T);
    };
  }
}
