import { Component, Input, OnInit,ViewChild,ElementRef } from '@angular/core';
import {DefaultEditor } from 'ng2-smart-table';

@Component({
  selector: 'app-select',
  template: `
  <nb-select [(ngModel)]="value" [disabled]="isDisabled" (selectedChange)="checkValue()">
    <nb-option value="male">Άντρας</nb-option>
    <nb-option value="female">Γυναίκα</nb-option>
    <nb-option value="other">Άλλο</nb-option>
    </nb-select>
      
  `
})
export class SelectComponent extends DefaultEditor implements OnInit {
 
  @Input() value: any;
  @Input() rowData: any;

  isDisabled: boolean;
  constructor() { 
    super();
  }

  ngOnInit() {
    this.isDisabled=true;
    if(typeof this.cell!="undefined")
    {
      this.isDisabled=false;
      this.value=this.cell.newValue;
    }
  }

  checkValue()
  {
    this.cell.newValue=this.value;
  }


}