import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable } from 'rxjs/Observable';
import { of } from 'rxjs/observable/of';
import { catchError } from 'rxjs/operators';

import { environment } from '@env/environment';

const httpOptions = {
  headers: new HttpHeaders({ 'Content-Type': 'application/json' })
};

@Injectable()
export class ApiService {

  private apiUrl = environment.api.endpoints.base;
  private weatherUrl = environment.data.endpoints.base;  // URL to web api
  private weatherApiid = environment.data.endpoints.appiId;
  constructor(private http: HttpClient) { }

 
  public GetStationsList(): Observable<any>{
    var url=this.apiUrl+'/locations/stations'
    return this.http.get<any>(url,httpOptions).pipe(
      catchError(this.handleError('getUseList'))
    );
  }

  GetWeatherPrediction(latitude:String,longitude:String): Observable<any>{
    const url = this.weatherUrl+'2.5/onecall?lat='+latitude+'&lon='+longitude+'&exclude=minutely,alerts&appid='+this.weatherApiid+"&units=metric";
    console.log(url)
    return this.http.get<any>(url);
  }
    
  GetHistoricalWeatherConditions(location:number,variable:string,startDate:string,endDate:string):Observable<any>{
    const url = this.apiUrl+'conditions/location?locationId='+location+"&variable="+variable+"&startDate="+startDate+"&endDate="+endDate;
    return this.http.get<any>(url,httpOptions) .pipe(
        catchError(this.handleError('updateUser'))
      );
  }

  /**
   * Handle Http operation that failed.
   * Let the app continue.
   * @param operation - name of the operation that failed
   * @param result - optional value to return as the observable result
   */
  private handleError<T>(operation = 'operation', result?: T) {
    return (error: any): Observable<T> => {

      // TODO: send the error to remote logging infrastructure
      console.error(error); // log to console instead

      // Let the app keep running by returning an empty result.
      return of(result as T);
    };
  }
}
