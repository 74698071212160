import { Component } from '@angular/core';


@Component({
    selector: 'mobile-app-component',
    template: '<nb-card><nb-card-header>Η εφαρμογή για κινητές συσκευές θα είναι σύντομα διαθέσιμη!</nb-card-header></nb-card>',
  })
  export class MobileAppComponent {
    constructor() { 
       
      }
    
  }