import { Component, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import {
  NbPopoverDirective,
  NbGlobalPhysicalPosition,
  NbGlobalPosition,
  NbToastrService,
} from '@nebular/theme';
import { UserService } from '@app/@core/data/user/user.service';

@Component({
  selector: 'ngx-password-update',
  templateUrl: './password-update.component.html',
  styleUrls: ['./password-update.component.scss']
})
export class PasswordUpdateComponent implements OnInit {
  @ViewChild(NbPopoverDirective, { static: true }) popover: NbPopoverDirective;
  passwordChangeForm: FormGroup;

  constructor(
    private fb: FormBuilder,
    private toastrService: NbToastrService,
    private userService: UserService,
  ) { }

  ngOnInit() {
    this.passwordChangeForm = this.fb.group({
      currentPass: ['', [Validators.required]],
      newPass: ['', [Validators.required]],
      rePass: ['', [Validators.required, Validators.pattern]],
    });
  }

  changePass() {
    this.passwordChangeForm.get('currentPass').markAsDirty();
    this.passwordChangeForm.get('newPass').markAsDirty();
    this.passwordChangeForm.get('rePass').markAsDirty();

    if (this.passwordChangeForm.invalid) {
      return;
    }

    this.userService.update(this.passwordChangeForm.value).subscribe(result => {
      if (!result) {
        this.showToast(
          'danger',
          'Password not updated',
          'Make sure you entered your current password correctly',
          3000,
          NbGlobalPhysicalPosition.TOP_RIGHT);
      } else {
        this.showToast('success', 'Password updated', '', 3000, NbGlobalPhysicalPosition.TOP_RIGHT);
        this.passwordChangeForm.get('rePass').clearAsyncValidators();
        this.passwordChangeForm.reset();
        setTimeout(() => {
          this.popover.hide();
        }, 300);
      }
    });
  }

  private showToast(type: any, title: string, body: string, duration: number, position: NbGlobalPosition) {
    const config = {
      status: type,
      destroyByClick: true,
      duration: duration,
      hasIcon: true,
      position: position,
      preventDuplicates: false,
    };

    this.toastrService.show(body, `${title}`, config);
  }

}
