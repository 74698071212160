import { NbAuthOAuth2JWTToken } from '@nebular/auth';
// export const environment = {
//   production: false,
//   auth: {
//     endpoints: {
//       base: 'http://localhost:3000/auth/',
//       register: 'register',
//       activate: 'activate',
//       login: 'login',
//       logout: 'logout',
//       refreshToken: 'refresh-token',
//       requestPass: 'request-pass',
//       resetPass: 'reset-pass',
//     },
//     token: {
//       class: NbAuthOAuth2JWTToken,
//       key: 'data',
//     }
//   },
//   api: {
//     endpoints: {
//       base: 'http://localhost:3000/api/',

//     },
//   },
//   data: {
//     endpoints: {
//       base: 'https://envi.pavlosmelas.gr/pr.php/',
//       appiId:'9500f48694fb03b50d4705eef1a299eb'
//     },
//   },
//   dateFormat: 'yyyy-MM-dd',
// };

export const environment = {
  production: false,
  auth: {
    endpoints: {
      base: 'https://envi.pavlosmelas.gr/api/auth/',
      register: 'register',
      activate: 'activate',
      login: 'login',
      logout: 'logout',
      refreshToken: 'refresh-token',
      requestPass: 'request-pass',
      resetPass: 'reset-pass',
    },
    token: {
      class: NbAuthOAuth2JWTToken,
      key: 'data',
    }
  },
  api: {
    endpoints: {
      base: 'https://envi.pavlosmelas.gr/api/api/',

    },
  },
  data: {
    endpoints: {
      base: 'https://envi.pavlosmelas.gr/pr.php/',
      appiId:'9500f48694fb03b50d4705eef1a299eb'
    },
  },
  dateFormat: 'yyyy-MM-dd',
};